import React, { createContext, useState, useCallback } from 'react';

const initialToast = {
  message: '',
  isError: false,
  visible: false,
};

export const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(initialToast);

  const show = useCallback((args) => {
    setToast({ ...initialToast, visible: true, ...args });
  }, []);

  const hide = useCallback(() => {
    setToast({ ...toast, visible: false });
  }, [toast]);

  return (
    <ToastContext.Provider
      value={{
        hide,
        show,
        toast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
